import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-servizi',
  templateUrl: './servizi.component.html',
  styleUrls: ['./servizi.component.scss']
})
export class ServiziComponent implements OnInit {

  closeResult: string;
  public isCollapsed = true;

  services = [
    {title: 'Redazione Documento di Valutazione dei Rischi comprensivo'},
    {title: 'Sopralluoghi di personale esperto su tematiche e richieste specifiche'},
    {title: 'Redazione di POS (Piano Operativo di Sicurezza) e PSC (Piano di Sicurezza e coordinamento)'},
    {title: 'PIMUS'},
    {title: 'Redazione D.U.V.R.I'},
    {title: 'Stesura Piani di evacuazione'},
    {title: 'Redazione protocolli anti-contagio (COVID-19)'},
    {title: 'Valutazione strumentale dell’esposizione dei lavoratori ai rischi fisici (rumore)'},
    {title: 'Pratiche antincendio (CPI)'},
    {title: 'Rilievi di gas RADON e valutazione del rischio'},
    {title: 'Corsi di formazione sicurezza sul lavoro Dlgs 81/08'},
    {title: 'Sorveglianza sanitaria'},
    {title: 'Stress lavoro-correlato'},
    {title: 'HACCP'},
    {title: 'Manuale di autocontrollo piscine'},
    {title: 'Istanze di autorizzazione all\'installazione di impianti di videosorveglianza e geolocalizzazione nei luoghi di lavoro'},
    {title: 'Verifiche di messa a terra DPR 462/01'},
  ];



  constructor(private modalService: NgbModal) { }

  ngOnInit() {}

  open(content) {
    this.modalService.open(content, {centered: true, ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
