import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formazione',
  templateUrl: './formazione.component.html',
  styleUrls: ['./formazione.component.scss']
})
export class FormazioneComponent implements OnInit {

  courses = [
    {title: 'Formazione lavoratori', rif_norm: 'A.S.R. 21/12/2011', duration_form: 'Rischio Basso 8 ore<br> Rischio Medio 12 ore<br> Rischio alto 16 ore', duration_upg: '6 ore', renewal: '5 anni', state: true},
    {title: 'Responsabile Servizio Prevenzione e Protezione (RSPP) Datore di lavoro', rif_norm: 'A.S.R. 21/12/2011', duration_form: 'Rischio Basso 16 ore<br> Rischio Medio 32 ore<br> Rischio alto 48 ore', duration_upg: '<br>Rischio Basso 6 ore<br> Rischio Medio 10 ore<br> Rischio alto 14 ore', renewal: '5 anni', state: true},
    {title: 'Preposto', rif_norm: 'A.S.R. 21/12/2011', duration_form: '8 ore', duration_upg: '6 ore', renewal: '5 anni', state: true},
    {title: 'Dirigenti', rif_norm: 'A.S.R. 21/12/2011', duration_form: '16 ore', duration_upg: '6 ore', renewal: '5 anni', state: true},

    {title: 'Responsabile/Addetto Servizio Prevenzione e Protezione (RSPP/ASPP) Modulo A - Base', rif_norm: 'A.S.R. 07/07/2016', duration_form: '28 ore', duration_upg: '', renewal: '', state: true},
    {title: 'Responsabile/Addetto Servizio Prevenzione e Protezione (RSPP/ASPP) Modulo B + SP2 (cave e costruzioni)', rif_norm: 'A.S.R. 07/07/2016', duration_form: '60 ore', duration_upg: '40 ore e/o corso agg. Coordinatore alla sicurezza', renewal: '5 anni', state: true},
    {title: 'Responsabile/Addetto Servizio Prevenzione e Protezione (RSPP/ASPP) Modulo C - Specializzazione', rif_norm: 'A.S.R. 07/07/2016', duration_form: '24 ore', duration_upg: '', renewal: '', state: true},

    {title: 'Rappresentante dei Lavoratori per la Sicurezza (RLS)', rif_norm: 'D.Lgs. n.81/08 (art. 37 comma 11)', duration_form: '32 ore', duration_upg: '4 ore < 50 lavoratori<br> 8 ore > 50 lavoratori', renewal: '1 anno', state: true},

    {title: 'Operatore/Addetto al montaggio, smontaggio, trasformazione ponteggi (PIMUS)', rif_norm: 'D.Lgs. 235/03 D.Lgs. n.81/08 (art. 136, All XXI)', duration_form: '28 ore', duration_upg: '4 ore', renewal: '4 anni', state: true},
    {title: 'Preposto al montaggio, smontaggio, trasformazione ponteggi (PIMUS)', rif_norm: 'D.Lgs. 235/03 D.Lgs. n.81/08 (art. 136, All XXI)', duration_form: '28 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},

    {title: 'Coordinatore Sicurezza', rif_norm: 'D.Lgs. n.81/08 Allegato XIV', duration_form: '120 ore', duration_upg: '40 ore e/o corso agg. RSPP Mod. B', renewal: '5 anni', state: true},

    {title: 'Segnaletica Stradale (Operatori)', rif_norm: 'Decreto 22/01/2019', duration_form: '8 ore', duration_upg: '6 ore', renewal: '5 anni', state: true},
    {title: 'Segnaletica Stradale (Preposti)', rif_norm: 'Decreto 22/01/2019', duration_form: '12 ore', duration_upg: '6 ore', renewal: '3 anni', state: true},

    {title: 'Antincendio', rif_norm: 'Dip. VV.FF. circ n. 12653 del 23/02/2011', duration_form: 'Rischio Basso 4 ore<br> Rischio Medio 8 ore<br> Rischio Alto 16 ore', duration_upg: '<br>Rischio Basso 2 ore<br> Rischio Medio 5 ore<br> Rischio Alto 8 ore', renewal: '3 anni', state: true},

    {title: 'Primo Soccorso Aziendale Gruppo A', rif_norm: 'D.M. n. 388/2003', duration_form: '16 ore', duration_upg: '6 ore', renewal: '3 anni', state: true},
    {title: 'Primo Soccorso Aziendale Gruppo B e C', rif_norm: 'D.M. n. 388/2003', duration_form: '12 ore', duration_upg: '4 ore', renewal: 'Aggiornamento periodico non definito dalla specifica normativa. Obbligatorio come requisito di carattere generale previsto dall’art 37, comma 6 del D.Lgs. 81/08', state: true},

    {title: 'H2S+Ambienti confinanti / Ambienti confinanti', rif_norm: 'D.P.R. n. 177 14/09/2011', duration_form: '8 - 16 ore', duration_upg: '4 - 8 ore', renewal: '5 anni', state: true},
    {title: 'DPI III Cat. Lavori in quota', rif_norm: 'D.Lgs. n.81/08 art. 37 c.3<br> D.Lgs. n.81/08 art. 77 c.5a', duration_form: '8 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},

    {title: 'Piattaforme di lavoro Mobili Elevabili (PLE) (con e senza stabilizzatori)', rif_norm: 'A.S.R. 22/02/2012', duration_form: '10 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Gru a torre (rotazione alta e bassa)', rif_norm: 'A.S.R. 22/02/2012', duration_form: '14 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Gru mobile (modulo base)', rif_norm: 'A.S.R. 22/02/2012', duration_form: '14 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Gru per Autocarro', rif_norm: 'A.S.R. 22/02/2012', duration_form: '12 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Carrelli Industriali Semoventi', rif_norm: 'A.S.R. 22/02/2012', duration_form: '12 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Carrelli Semoventi a Braccio Telescopico Rotativo', rif_norm: 'A.S.R. 22/02/2012', duration_form: '12 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Carrelli/Sollevatori/Elevatori Semoventi Telescopici Rotativi', rif_norm: 'A.S.R. 22/02/2012', duration_form: '16 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Trattori agricoli o forestali (a ruote)', rif_norm: 'A.S.R. 22/02/2012', duration_form: '8 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Escavatori idraulici', rif_norm: 'A.S.R. 22/02/2012', duration_form: '10 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Pale Caricatrici Frontali', rif_norm: 'A.S.R. 22/02/2012', duration_form: '10 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Terne', rif_norm: 'A.S.R. 22/02/2012', duration_form: '10 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Escavatori idraulici, caricatori frontali e terne', rif_norm: 'A.S.R. 22/02/2012', duration_form: '16 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},
    {title: 'Pompe per calcestruzzo', rif_norm: 'A.S.R. 22/02/2012', duration_form: '14 ore', duration_upg: '4 ore', renewal: '5 anni', state: true},

    {title: 'PES e PAV', rif_norm: 'D.Lgs. 81/08 art. 82<br> Norma CEI EN 50110 Norma CEI EN-27:2014', duration_form: '16 ore', duration_upg: '', renewal: 'Minimo 4 anni', state: true}
  ];

  constructor() { }

  ngOnInit() {
  }

}
