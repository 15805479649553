import {Component, OnInit} from '@angular/core';
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // @ViewChild('newSwiper') newSwiper: any;

  slides = [
    {title: 'Consulenza in materia di salute e sicurezza <br> sui luoghi di lavoro', subtitle: 'Mettiamo a disposizione delle aziende tutte le conoscenze, le esperienze e le procedure necessarie, al fine di ottemperare agli obblighi richiamati dal D.Lgs. 81/08.', image: '/assets/images/consulenza_sul_lavoro.webp', url: 'consulenza-sicurezza-sul-lavoro'},
    {title: 'Scegli i nostri corsi di sicurezza sul lavoro', subtitle: 'Saremo felici di raccontarvi i punti di forza dei nostri corsi e il valore di una formazione mirata!', image: '/assets/images/corsi_sicurezza_sul_lavoro.webp', url: 'corsi-sicurezza-sul-lavoro'},
    {title: 'Garantiamo la salute e la sicurezza <br> nella tua Impresa', subtitle: 'Individuiamo le situazioni di rischio e attiviamo le misure necessarie per rendere l’ambiente di lavoro più sicuro.', image: '/assets/images/medicina_del_lavoro.webp', url: 'medicina-del-lavoro'},
    {title: 'Misurazione Gas Radon', subtitle: 'Valutiamo la concentrazione media di attività di gas RADON nell\'aria, garantendo piena conformità a quanto previsto dalla legge in oggetto.', image: '/assets/images/valutazione_gas_radon.webp', url: 'valutazione-gas-radon'}
  ];

  constructor() {}

  ngOnInit() {}


/*  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }*/

}
