import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SwiperModule} from 'swiper/angular';

import { AppComponent } from './app.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HomeComponent} from './home/home.component';

import {FooterComponent} from './footer/footer.component';
import { ServiziComponent } from './servizi/servizi.component';
import { FormazioneComponent } from './formazione/formazione.component';
import { MedicineComponent } from './medicine/medicine.component';
import { WhoisComponent } from './whois/whois.component';
import { RadonComponent } from './radon/radon.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    ServiziComponent,
    FormazioneComponent,
    MedicineComponent,
    WhoisComponent,
    RadonComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    SwiperModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
