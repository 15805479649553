import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta, private router: Router, @Inject(DOCUMENT) private document: any) { }

  updateMetaTags(data)  {
    this.title.setTitle(data.title);

    if (data.description) {
      this.meta.updateTag({ name: 'description', content: data.description });
    } else {
      this.meta.removeTag('name=\'description\'');
    }

    if (data.canonical) {
      this.updateCanonicalUrl(environment.apiUrl + data.canonical);
    } else {
      this.updateCanonicalUrl(environment.apiUrl + this.router.url);
    }

    this.meta.updateTag({ property: 'og:locale', content: 'it_IT' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });

    if (data.ogUrl) {
      this.meta.updateTag({ property: 'og:url', content: environment.apiUrl + data.ogUrl });
    } else {
      this.meta.updateTag({ property: 'og:url', content: environment.apiUrl + this.router.url });
    }

    if (data.ogTitle) {
      this.meta.updateTag({ property: 'og:title', content: data.ogTitle });
    } else {
      this.meta.removeTag('property=\'og:title\'');
    }

    if (data.ogDescription) {
      this.meta.updateTag({ property: 'og:description', content: data.ogDescription });
    } else {
      this.meta.removeTag('property=\'og:description\'');
    }

    if (data.ogImage) {
      this.meta.updateTag({ property: 'og:image', content: environment.apiUrl + data.ogImage });
    } else {
      this.meta.removeTag('property=\'og:image\'');
    }
  }

  updateCanonicalUrl(url: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

}
