import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {WhoisComponent} from './whois/whois.component';
import {ServiziComponent} from './servizi/servizi.component';
import {FormazioneComponent} from './formazione/formazione.component';
import {MedicineComponent} from './medicine/medicine.component';
import {RadonComponent} from './radon/radon.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: {
      title: 'SicurLav Servizi - Sicurezza sul Lavoro',
      description: 'Garantiamo la Sicurezza sul Lavoro! Ci occupiamo di tutta la documentazione necessaria per le aziende, offrendo consulenza e formazione in provincia di Lecce.',
      canonical: '/',
      ogUrl: '/',
      ogTitle: 'SicurLav Servizi',
      ogDescription: 'Garantiamo la Sicurezza sul Lavoro! Ci occupiamo di tutta la documentazione necessaria per le aziende, offrendo consulenza e formazione in provincia di Lecce.',
      ogImage: '/assets/images/sicurlav_servizi.webp',
    }
  },
  {
    path: 'chi-siamo',
    component: WhoisComponent,
    data: {
      title: 'Chi Siamo? | SicurLav Servizi',
      description: 'Siamo una società di consulenza e formazione sulla sicurezza e igiene nei luoghi di lavoro con sede a Gallipoli',
      canonical: '/chi-siamo',
      ogUrl: '/chi-siamo',
      ogTitle: 'Chi Siamo? | SicurLav Servizi',
      ogDescription: 'Siamo una società di consulenza e formazione sulla sicurezza e igiene nei luoghi di lavoro con sede a Gallipoli',
      ogImage: '/assets/images/sicurlav_servizi.webp',
    }
  },
  {
    path: 'consulenza-sicurezza-sul-lavoro',
    component: ServiziComponent,
    data: {
      title: 'Consulenza Sicurezza sul Lavoro | SicurLav Servizi',
      description: 'Esperti Consulenti per la migliore gestione e organizzazione degli aspetti di igiene e sicurezza sul lavoro aziendale',
      canonical: '/consulenza-sicurezza-sul-lavoro',
      ogUrl: '/consulenza-sicurezza-sul-lavoro',
      ogTitle: 'Consulenza Sicurezza sul Lavoro | SicurLav Servizi',
      ogDescription: 'Esperti Consulenti per la migliore gestione e organizzazione degli aspetti di igiene e sicurezza sul lavoro aziendale',
      ogImage: '/assets/images/consulenza.webp',
    }
  },
  {
    path: 'corsi-sicurezza-sul-lavoro',
    component: FormazioneComponent,
    data: {
      title: 'Formazione e Corsi Sicurezza sul Lavoro | SicurLav Servizi',
      description: 'Presentazione dei Corsi per la Formazione sulla Sicurezza sul posto di lavoro, con calendario aggiornato',
      canonical: '/corsi-sicurezza-sul-lavoro',
      ogUrl: '/corsi-sicurezza-sul-lavoro',
      ogTitle: 'Formazione e Corsi Sicurezza sul Lavoro | SicurLav Servizi',
      ogDescription: 'I nostri Corsi per la Formazione sulla Sicurezza sul posto di lavoro, con calendario aggiornato',
      ogImage: '/assets/images/corsi.webp',
    }
  },
  {
    path: 'medicina-del-lavoro',
    component: MedicineComponent,
    data: {
      title: 'Medicina del Lavoro | SicurLav Servizi',
      description: 'Offriamo consulenza professionale e qualificata in materia di medicina del lavoro per tutte le tipologie di azienda, grazie a medici professionisti.',
      canonical: '/medicina-del-lavoro',
      ogUrl: '/medicina-del-lavoro',
      ogTitle: 'Medicina del Lavoro | SicurLav Servizi',
      ogDescription: 'Offriamo consulenza professionale e qualificata in materia di medicina del lavoro per tutte le tipologie di azienda, grazie a medici professionisti.',
      ogImage: '/assets/images/medicina.webp',
    }
  },
  {
    path: 'valutazione-gas-radon',
    component: RadonComponent,
    data: {
      title: 'Valutazione Gas Radon | SicurLav Servizi',
      description: 'Consulenza e servizi per la valutazione ed eventuale misurazione del rischio radon in grandi e piccole aziende, specificando criteri di individuazione degli ambienti di lavoro.',
      canonical: '/valutazione-gas-radon',
      ogUrl: '/valutazione-gas-radon',
      ogTitle: 'Valutazione Gas Radon | SicurLav Servizi',
      ogDescription: 'Consulenza e servizi per la valutazione ed eventuale misurazione del rischio radon in grandi e piccole aziende, specificando criteri di individuazione degli ambienti di lavoro.',
      ogImage: '/assets/images/medicina.webp',
    }
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 20]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
