import {Component, ElementRef, Inject, OnInit, Renderer, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import 'rxjs-compat/add/operator/filter';

import {NavbarComponent} from './navbar/navbar.component';
import {Subscription} from 'rxjs';

import {SeoService} from './seo.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private subscription: Subscription;
  @ViewChild(NavbarComponent, {static: false}) navbar: NavbarComponent;

  constructor(private renderer: Renderer, private router: Router,
              private activatedRoute: ActivatedRoute,
              private seoService: SeoService,
              private element: ElementRef, public location: Location) {
  }

  ngOnInit() {

    const nav: HTMLElement = this.element.nativeElement.children[0].children[0];

    this.subscription = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {

      this.seoMetaTags();

      this.navbar.sidebarClose();

      this.renderer.listenGlobal('window', 'scroll', () => {
          if (window.scrollY > 150 || window.pageYOffset > 150) {
            nav.classList.remove('navbar-transparent');
          } else {
            nav.classList.add('navbar-transparent');
          }
      });
    });

  }

  seoMetaTags() {
    let rt = this.getChild(this.activatedRoute);
    rt.data.subscribe(data => {
      // console.log(data);
      this.seoService.updateMetaTags(data);
    });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
